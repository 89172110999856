import EducationCard from "./EducationCard";

function Education() {

    const educations = [
        {
          key: "edu04",
          year: "2019-2024",
          school: "Galatasaray University, Istanbul, Turkey",
          program: "B.A. in Philosophy",
          extra: "Double Degree with Université Paris 1 Panthéon-Sorbonne",
        },
        {
          key: "edu03",
          year: "2012-2018",
          school: "Middle East Technical University (METU), Ankara, Turkey",
          program: "B.S. in Industrial Engineering",
          extra: "Minor in Logic and Philosophy of Science",
        },
        {
          key: "edu01",
          year: "2008-2012",
          school: "Eskisehir Science High School (EFFL), Eskisehir, Turkey",
          program: "Quantitative studies",
          extra: false,
        },

      ];
    return (
        <section id="education-section">
            <h2>Education</h2>
            {educations.map((educationdetail) => (
                <EducationCard key={educationdetail.key} educationdetail={educationdetail}/>
                ))}
        </section>
    )
}

export default Education