import WorkCard from "./WorkCard";

function Works() {
    const projects = [
        {
            getImageSrc: () => require("../assets/projects/yazituna.jpg"),
            name: "Yazituna: Portfolio-v1",
            exp: "This is the first version of my portfolio, and the first live project that I built from scratch with React. I created my personal brand, designed the interface on Figma, produced the content on iPhone during daily commute, developed the site on VS Code, on Ubuntu virtualized using VMware Fusion. And, here is the result.",
            skills: ["UX Design", "Wireframing", "Prototyping", "Figma", "Adobe Ai", "HTML", "CSS", "JS (ES6)", "React"],
            url: false,
        },
        {
            getImageSrc: () => require("../assets/projects/diferans.jpg"),
            name: "Diferans Engineering & Simulation",
            exp: "Diferans Engineering & Simulation was founded in 2018 by experienced engineers in order to reduce the foreign dependency of industry-oriented engineering simulations and to make these advanced engineering applications accessible and sustainable for every industrial enterprise in Turkiye regardless of their scale. We curated a website for their corporate and their flagship CFD product - SimFD.",
            skills: ["Adobe Ai", "HTML", "CSS", "JS (ES6)", "Bootstrap", "PHP", "SEO", "Emailing"],
            url: "https://diferans.com/",
        },
        {
            getImageSrc: () => require("../assets/projects/efflmezun.jpg"),
            name: "EFFL Alumni Association",
            exp: "I have been working voluntarily for the alumni association of my high school for some years. After I took the duty, apart from having been actively contributing to many projects, I have refurnished our logo, reorganized all the communication channels, built a website, designed and produced social media content, and designed many branded products.",
            skills: ["Adobe Ai", "Adobe Ps", "HTML", "CSS", "JS", "Bootstrap", "Social Media", "Fonzip", "Project Management"],
            url: "https://efflmezun.org",
        },
        {
            getImageSrc: () => require("../assets/projects/kaptankahve.jpg"),
            name: "Kaptan Kahve",
            exp: "Three of my friends came together and decided to open a coffee shop named Kaptan (Captain in Turkish). They handed me the interior design of the coffee shop. In turn, I handed them a brand, some brand applications, social media pages, a website and a Google Business profile.",
            skills: ["Adobe Ai", "Adobe Ps", "HTML", "CSS", "JS", "Bootstrap", "Social Media", "Google Business"],
            url: "https://www.yazituna.com/kaptankahve",
        },
        {
            getImageSrc: () => require("../assets/projects/yavuzakbulut.jpg"),
            name: "Yavuz Akbulut: Academic Portfolio",
            exp: "Yavuz Akbulut is a professor in the Faculty of Education at Anadolu University. This custom design merges seamlessly a portfolio and a vCard into a unique website. This lean yet useful design is created completely from scratch based on the specific needs of an academic, in line with the identity of the university.",
            skills: ["Adobe Ps", "Adobe Ai", "HTML", "CSS", "JS"],
            url: "https://yavuzakbulut.net/",
        },
        {
            getImageSrc: () => require("../assets/projects/nekodu.jpg"),
            name: "Nekodu Technology",
            exp: "Nekodu was the general-purpose technology company that we established as the Digigammon team after having spent some time on Digitimer and Transcriptor projects. As a co-founder of the company, I designed the brand, created brand applications, launched social media pages and built a website.",
            skills: ["Adobe Ai", "Adobe Ps", "HTML", "CSS", "JS", "Bootstrap", "Social Media"],
            url: "https://nekodu.com/",
        },
        {
            getImageSrc: () => require("../assets/projects/dicoturc.jpg"),
            name: "Dictionnaireturc",
            exp: "To major philosophy, I chose a francophone university in Istanbul. During the preparatory year where we polished our French, the idea of launching an Instagram page where literal translations of famous Turkish expressions into French came to our mind. It was a fun way of learning. So, I created a brand, launched the page, and since then have managed the page and designed hundreds of posts. I am also a part of a team where we produce translation ideas. Though I have not been very active recently, the page has about 8k followers and has an important place among Turkish francophones.",
            skills: ["Adobe Ai", "Social Media", "Project Management", "French"],
            url: "https://instagram.com/dictionnaireturc",
        },
        {
            getImageSrc: () => require("../assets/projects/digigammon.jpg"),
            name: "Digigammon",
            exp: "Digigammon was the team that came together to create an automatic transcription solution for backgammon players, who were doing it manually wasting hours. As for my part, I designed the brand, launched and managed social media pages, designed and built a website, and contributed to countless technical and nontechnical tasks.",
            skills: ["Adobe Ai", "Adobe Ps", "Adobe Pr", "HTML", "CSS", "JS", "Bootstrap", "Social Media"],
            url: "https://digigammon.com/",
        },
        {
            getImageSrc: () => require("../assets/projects/transcriptor.jpg"),
            name: "Transcriptor",
            exp: "As part of the Digigammon team, we developed the Transcriptor app which allows automatic transcription of backgammon matches by processing match videos using AI models. As for my part, I designed the app’s logo and interface, built the interface, developed some part of its functionality , and conducted countless tests. The app works on Windows and is integrated with the Digitimer app. It is based on Python as it allows better communication with the AI models built in Python. ",
            skills: ["UX Design", "Adobe Ai", "Adobe Xd", "Python", "Kivy"],
            url: "https://github.com/DigiGammon/digigammon.git",
        },
        {
            getImageSrc: () => require("../assets/projects/digitimer.jpg"),
            name: "Digitimer",
            exp: "For the Transcriptor project, in which we aimed to automatically transcribe backgammon matches, a smart timer and scoreboard app that was also capable of recording the match video was needed. So, I designed the app’s logo, its interface, conduct tests and developed a great deal of the app using Python and Kivy library. The app works on Windows platforms, as it allows easy control of an external camera. It is based on Python as it allows better communication with the AI models built in Python. ",
            skills: ["UX Design", "Adobe Ai", "Adobe Xd", "Python", "Kivy"],
            url: "https://github.com/DigiGammon/digigammon.git",
        },
        {
            getImageSrc: () => require("../assets/projects/blender.jpg"),
            name: "Artificial Data Generation",
            exp: "For the Transcriptor project, in which we aimed to automatically transcribe backgammon matches, we needed to visually detect the dice on frames of videos taken during the matches. To train the detection models, some serious quantity of dice images were required. To strengthen the dataset and increase the quantity, I generated an artificial dataset. For this, I built a 3D model of some dice and their surroundings on Blender. Then, I wrote a Python script that randomizes the environmental variables, automatically performs rendering thousands of times via loops, cuts the part of the images where the dice were located and outputs the result. At the end, we had tens of thousands of realistic dice renders.",
            skills: ["AI", "Blender", "Python"],
            url: "https://github.com/yazituna/blender-dice-generator.git",
        },
        /*{
            getImageSrc: () => require("../assets/projects/littlelemon.jpg"),
            name: "Meta Front-End: Little Lemon Website",
            exp: "This is where I believe I really became capable of building web apps with React. For the capstone project of the program, I built from scratch a website where the focus is on the reservation system for a hypothetical Mediterranean-style restaurant. I designed the interface on Figma, built the website in VS Code, and conducted unit tests. Of course, all these while reading some serious documentation.",
            skills: ["UX Design", "Wireframing", "Mockups", "Prototyping", "Figma", "Adobe Ai", "HTML", "CSS", "JS (ES6)", "React", "Formik", "Unit Tests"],
            url: "https://github.com/yazituna/meta-littlelemon.git",
        },
        {
            getImageSrc: () => require("../assets/projects/dronedelivery.jpg"),
            name: "Google UX Design: Drone Delivery App",
            exp: "During the program, I was required to create an interface according to a Sharpen prompt. And the prompt was: create an app for a logistics company that delivers with drones. Interesting isn’t it? Drone delivery was not commercially available yet, yet there were initiatives. So, based on a research of this newly emerging area and current logistics solutions, I came up with an app design following delicately the iterative design thinking process.",
            skills: ["UX Research", "UX Design", "Figma", "Wireframing", "Mockups", "Prototyping", "Usability Studies"],
            url: false,
        },
        {
            getImageSrc: () => require("../assets/projects/elelegitim.jpg"),
            name: "Google UX Design: Elelegitim",
            exp: "During the program, I needed to design a responsive website for a local nonprofit organization. So, I designed a website, more specifically a voluntary registration form for a local association which aims to achieve equality in education by bringing together children in need of private tutoring and potential tutors. I carried out the design of course by following the design thinking process.",
            skills: ["UX Research", "UX Design", "Wireframing", "Mockups", "Prototyping", "Adobe Xd", "Adobe Ps", "Usability Studies"],
            url: false,
        },
        {
            getImageSrc: () => require("../assets/projects/chesstanbul.jpg"),
            name: "CalArts Graphic Design: Chesstanbul",
            exp: "This was the capstone project of the program, where I learned about the fundamentals of graphic design, typography and image making through a rigorous process. In this specific project, it was required to create a business along with its history and philosophy, and to build a brand for it. The process included ideation, moodboard creation, type explorations, color explorations, logo and mark development and brand applications.",
            skills: ["Adobe Ai", "Adobe Ps", "Typography", "Imagemaking", "Moodboard", "Branding"],
            url: false,
        },
        {
            getImageSrc: () => require("../assets/projects/kefircim.jpg"),
            name: "CalArts UI / UX Design: Kefircim",
            exp: "This was the first time when I learnt about UX in a well-structured manner, so I wanted to include it despite its many flaws. The challenge was to create a local business and to design an ordering system for this hypothetical organization. I decided to create a local “kefir” shop, a traditional milk-based sour drink in Turkish cuisine. It is preferred mainly because it is extremely healthy, especially when it is hand-made and supplemented with different ingredients, which was what my hypothetical shop was doing. I really liked the idea and designed its website with the real intention of opening one, but didn’t have the opportunity and resources to do.",
            skills: ["UX Research", "UX Design", "Wireframing", "Moodboards", "Mockups", "Prototyping", "Adobe Ai", "Adobe Ps", "Adobe Xd"],
            url: false,
        }, */
    ];

      const moreprojects = [
        {
            getImageSrc: () => require("../assets/projects/yazituna.jpg"),
            name: "",
            exp: "",
            skills: [""],
            url: "h",
        },
      ];

      const displayMoreProjects = (anchor) => () => {
        const id = `${anchor}`
        const x = document.getElementById(id);
        const buttonmore = document.getElementById("see-more-button");
        const buttonless = document.getElementById("see-less-button");

        if (x.style.display === "block") {
            x.style.display = "none";
            buttonmore.style.display = "inline";
            buttonless.style.display = "none";
          } else {
            x.style.display = "block";
            buttonmore.style.display = "none";
            buttonless.style.display = "inline";
          }
      }

    return (
        <section id="works-section">
            <h2>Works</h2>
            <div>
                {projects.map((project) => (
                    <WorkCard key={project.name} project={project}/>
                ))}
            </div>
            {/* <a id="see-more-button" href={undefined} className="link-more-projects" onClick={displayMoreProjects('more-projects-container')}>See more projects ↓</a>
            <div id="more-projects-container">
                {moreprojects.map((moreproject) => (
                    <WorkCard key={moreproject.name} project={moreproject}/>
                ))}
            </div>
            <a id="see-less-button" href={undefined} className="link-more-projects" onClick={displayMoreProjects('more-projects-container')}>See fewer projects ↑</a> */}
        </section>
    )
}

export default Works